import lauden from '../../../images/slides/farms/lauden.png';
import lesnaWola from '../../../images/slides/farms/lesnawola.png';
import ulitivia from '../../../images/slides/farms/ulitivia.png';
import stangretka from '../../../images/slides/farms/stangretka.png';
import warszkowo from '../../../images/slides/farms/warszkowo.png';
import zordina from '../../../images/slides/farms/zordina.png';

const allPhotos = [
  { image: lesnaWola, name: 'Stadnina Koni Leśna Wola' },
  { image: stangretka, name: 'Stadnina Stangretka' },
  { image: warszkowo, name: 'Stajnia Warszkowo' },
  { image: lauden, name: 'Stajnia Lauden' },
  { image: ulitivia, name: 'OJK Ultivia' },
  { image: zordina, name: 'OJK Zordina' },
];

export default allPhotos;
