import React, { useState, useEffect, useRef } from 'react';
import Carousel from 'react-slick';
import Img from 'gatsby-image';
import { uniqueId } from 'lodash';
import { graphql, useStaticQuery } from 'gatsby';
import classNames from 'classnames';
import { Transition, TransitionGroup } from 'react-transition-group';
import Heading from '../shared/heading';
import Dots from '../shared/slider/dots';
import t from '../../locales';

const duration = 600;

const defaultStyle = {
  transition: `opacity ${duration}ms`,
  opacity: 0,
};

const transitionStyles = {
  entering: {
    opacity: 0,
    height: 0,
  },
  entered: {
    opacity: 1,
    height: 'auto',
  },
};

const FadeTransition = ({ children, in: inProp }) => (
  <Transition in={inProp} unmountOnExit={true} mountOnEnter={true}>
    {state => (
      <div
        style={{
          ...defaultStyle,
          ...transitionStyles[state],
        }}
      >
        {children}
      </div>
    )}
  </Transition>
);

export const screenMobileImage = graphql`
  fragment screenMobileImage on File {
    childImageSharp {
      fluid(quality: 100, maxWidth: 300, maxHeight: 398) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`;

const MobileSwitcher = () => {
  const tabs = ['owner', 'instructor', 'client'];

  const {
    calendar,
    management,
    rides_management,
    payments,
    statistics,
    news,
    scheduleday,
    news_client,
    easy_communication,
    horse_management,
    horse_service,
    instructor_availability,
    sms,
    rides_reservation,
    payments_client,
    tickets,
    tickets_client,
  } = useStaticQuery(graphql`
    query {
      calendar: file(relativePath: { eq: "screens/mobile/calendar.png" }) {
        ...screenMobileImage
      }
      management: file(relativePath: { eq: "screens/mobile/management.png" }) {
        ...screenMobileImage
      }
      rides_management: file(relativePath: { eq: "screens/mobile/rides_management.png" }) {
        ...screenMobileImage
      }
      payments: file(relativePath: { eq: "screens/mobile/payments.png" }) {
        ...screenMobileImage
      }
      statistics: file(relativePath: { eq: "screens/mobile/statistics.png" }) {
        ...screenMobileImage
      }
      news: file(relativePath: { eq: "screens/mobile/news.png" }) {
        ...screenMobileImage
      }
      scheduleday: file(relativePath: { eq: "screens/mobile/scheduleday.png" }) {
        ...screenMobileImage
      }
      news_client: file(relativePath: { eq: "screens/mobile/news_client.png" }) {
        ...screenMobileImage
      }
      easy_communication: file(relativePath: { eq: "screens/mobile/easy_communication.png" }) {
        ...screenMobileImage
      }
      horse_management: file(relativePath: { eq: "screens/mobile/horse_management.png" }) {
        ...screenMobileImage
      }
      horse_service: file(relativePath: { eq: "screens/mobile/horse_service.png" }) {
        ...screenMobileImage
      }
      instructor_availability: file(relativePath: { eq: "screens/mobile/instructor_availability.png" }) {
        ...screenMobileImage
      }
      sms: file(relativePath: { eq: "screens/mobile/sms.png" }) {
        ...screenMobileImage
      }
      rides_reservation: file(relativePath: { eq: "screens/mobile/rides_reservation.png" }) {
        ...screenMobileImage
      }
      payments_client: file(relativePath: { eq: "screens/mobile/payments_client.png" }) {
        ...screenMobileImage
      }
      tickets: file(relativePath: { eq: "screens/mobile/tickets.png" }) {
        ...screenMobileImage
      }
      tickets_client: file(relativePath: { eq: "screens/mobile/tickets_client.png" }) {
        ...screenMobileImage
      }
    }
  `);

  const features = {
    owner: [
      {
        key: 0,
        title: t('home.features.owner.0.title'),
        content: t('home.features.owner.0.content'),
        screen: calendar,
      },
      {
        key: 1,
        title: t('home.features.owner.1.title'),
        content: t('home.features.owner.1.content'),
        screen: management,
      },
      {
        key: 2,
        title: t('home.features.owner.2.title'),
        content: t('home.features.owner.2.content'),
        screen: payments,
      },
      {
        key: 3,
        title: t('home.features.owner.3.title'),
        content: t('home.features.owner.3.content'),
        screen: tickets,
      },
      {
        key: 4,
        title: t('home.features.owner.4.title'),
        content: t('home.features.owner.4.content'),
        screen: statistics,
      },
      {
        key: 5,
        title: t('home.features.owner.5.title'),
        content: t('home.features.owner.5.content'),
        screen: news,
      },
    ],
    instructor: [
      {
        key: 10,
        title: t('home.features.instructor.0.title'),
        content: t('home.features.instructor.0.content'),
        screen: scheduleday,
      },
      {
        key: 11,
        title: t('home.features.instructor.1.title'),
        content: t('home.features.instructor.1.content'),
        screen: rides_management,
      },
      {
        key: 12,
        title: t('home.features.instructor.2.title'),
        content: t('home.features.instructor.2.content'),
        screen: easy_communication,
      },
      {
        key: 13,
        title: t('home.features.instructor.3.title'),
        content: t('home.features.instructor.3.content'),
        screen: horse_management,
      },
      {
        key: 14,
        title: t('home.features.instructor.4.title'),
        content: t('home.features.instructor.4.content'),
        screen: sms,
      },
    ],
    client: [
      {
        key: 20,
        title: t('home.features.client.0.title'),
        content: t('home.features.client.0.content'),
        screen: rides_reservation,
      },
      {
        key: 21,
        title: t('home.features.client.1.title'),
        content: t('home.features.client.1.content'),
        screen: instructor_availability,
      },
      {
        key: 22,
        title: t('home.features.client.2.title'),
        content: t('home.features.client.2.content'),
        screen: news_client,
      },
      {
        key: 23,
        title: t('home.features.client.3.title'),
        content: t('home.features.client.3.content'),
        screen: payments_client,
      },
      {
        key: 24,
        title: t('home.features.client.4.title'),
        content: t('home.features.client.4.content'),
        screen: tickets_client,
      },
      {
        key: 25,
        title: t('home.features.client.5.title'),
        content: t('home.features.client.5.content'),
        screen: horse_service,
      },
    ],
  };

  const options = {
    infinite: true,
    autoplay: false,
    arrows: false,
  };

  return (
    <React.Fragment>
      {tabs.map(tab => {
        const sliderImages = features[tab].map(feature => feature.screen);
        return (
          <MobileSlider key={tab} sliderImages={sliderImages} tab={tab} options={options} features={features[tab]} />
        );
      })}
    </React.Fragment>
  );
};

export default MobileSwitcher;

const MobileSlider = ({ sliderImages, tab, options, features }) => {
  let refSlider = useRef();

  useEffect(() => {
    if (refSlider.current) refSlider.current.slickGoTo(activeIndex);
  });

  const [activeIndex, setActiveIndex] = useState(0);

  return (
    <div key={`mobile-${tab}`} className="mobile-switcher">
      <Heading inverted> {t(`general.${tab}`)} </Heading>
      <Carousel {...options} beforeChange={(_, next) => setActiveIndex(next)} ref={refSlider}>
        {features.map(feature => (
          <div key={uniqueId()} className="feature-slide">
            <Img fluid={feature.screen.childImageSharp.fluid} />
          </div>
        ))}
      </Carousel>
      <div>
        <Dots className="teal" slideCount={sliderImages.length} goToSlide={setActiveIndex} currentSlide={activeIndex} />
      </div>
      <TransitionGroup>
        {features.map((feature, key) => (
          <FadeTransition key={uniqueId()}>
            <div className={classNames('feature feature-slide', activeIndex !== key && 'hidden')}>
              <div>{feature.title}</div>
              <div>{feature.content}</div>
            </div>
          </FadeTransition>
        ))}
      </TransitionGroup>
    </div>
  );
};
