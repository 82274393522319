import React, { useEffect } from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import { connect } from 'react-redux';

import t from '../locales';
import Section from '../components/shared/section';
import CenteredText from '../components/shared/centred-text';
import Teal from '../components/shared/text';
import Slider from '../components/shared/slider/slider';
import { setPage } from '../store/app';
import FeaturesSwitcher from '../components/features-switcher/switcher';
import MobileSwitcher from '../components/features-switcher/mobile-switcher';
import SliderFarms from '../components/shared/slider/slider-small';
import FarmPhotos from '../components/shared/slider/farm-photos';

const Home = ({ data, isMobile, setPage }) => {
  useEffect(() => {
    setPage('home');
  }, [setPage]);

  const sliderImages = [
    <Img fluid={data.image1.childImageSharp.fluid} style={{ minHeight: '300px' }} />,
    <Img fluid={data.image2.childImageSharp.fluid} style={{ minHeight: '300px' }} />,
  ];

  return (
    <>
      <Slider sliderImages={sliderImages} isMobile={isMobile} />
      <Section className="bg-teal" style={{ marginTop: '-4px' }}>
        <CenteredText className="text-white" large bold>
          <div>{t('home.firstApp')}</div>
          <div>{t('home.firstAppFollowing')}</div>
          <div>{t('home.monthlySubscriptionInfo')}</div>
        </CenteredText>
      </Section>
      <Section>
        <CenteredText large bold>
          <div>
            {t('home.seeHow')} <Teal>{t('general.hm')}</Teal>
          </div>
          <div>{t('home.customers')}</div>
        </CenteredText>
      </Section>
      <Section className="bg-white switcher">{isMobile ? <MobileSwitcher /> : <FeaturesSwitcher />}</Section>
      <Section className="slider-section bg-white">
        <CenteredText large>
          <div>{t('home.usingOurApp')}</div>
        </CenteredText>
        <CenteredText large bold className="mb-8">
          <div>{t('home.checkWhoUses')}</div>
        </CenteredText>
        <SliderFarms sliderImages={FarmPhotos} />
      </Section>
    </>
  );
};

export const sliderImage = graphql`
  fragment sliderImage on File {
    childImageSharp {
      fluid(quality: 100, maxWidth: 1920, maxHeight: 760) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
`;

export const query = graphql`
  query {
    image1: file(relativePath: { eq: "slides/1.jpg" }) {
      ...sliderImage
    }
    image2: file(relativePath: { eq: "slides/2.jpg" }) {
      ...sliderImage
    }
  }
`;
export default connect(
  state => ({ pageName: state.app.pageName, isMobile: state.app.isMobile }),
  dispatch => ({
    setPage: pageName => dispatch(setPage(pageName)),
  })
)(Home);
