import React from 'react';
import { keyPressSpace } from '../../helpers';

const Point = ({ dotSize, setActive }) => {
  return (
    <div
      className="point"
      role="button"
      onClick={setActive}
      tabIndex={0}
      onKeyDown={e => keyPressSpace(e, setActive)}
      aria-label="Change slide"
      style={{
        height: dotSize,
        width: dotSize,
      }}
    />
  );
};

export default Point;
