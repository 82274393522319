import React, { useState } from 'react';
import classnames from 'classnames';
import Carousel from 'react-slick';
import arrowLeft from '../../../images/arrow-left-single.png';
import arrowRight from '../../../images/arrow-right-single.png';
import { keyPressSpace } from '../../../helpers';

const Arrow = ({ direction, onClick }) => (
  <div
    role="button"
    tabIndex={0}
    aria-label="Change slide"
    onClick={onClick}
    onKeyDown={e => keyPressSpace(e, onClick)}
  >
    <img
      className={classnames('arrow', direction)}
      src={direction === 'left' ? arrowLeft : arrowRight}
      alt={`arrow ${direction}`}
    />
  </div>
);

const Slider = props => {
  const [slideIndex, setSlideIndex] = useState(0);

  const options = {
    beforeChange: (_, next) => setSlideIndex(next),
    appendDots: dots => <ul style={{ bottom: 0 }}> {dots} </ul>,
    customPaging: i => <div className={classnames('dot', i === slideIndex && 'active')} />,
  };

  return (
    <Carousel
      nextArrow={<Arrow direction="right" />}
      prevArrow={<Arrow direction="left" />}
      infinite
      autoplay
      autoplaySpeed="60"
      arrows={!props.isMobile}
      dots
      {...options}
    >
      {props.sliderImages.map((image, index) => (
        <div key={index}> {image} </div>
      ))}
    </Carousel>
  );
};

export default Slider;
