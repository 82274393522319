import React from 'react';
import classnames from 'classnames';
import { uniqueId } from 'lodash';
import { keyPressSpace } from '../../../helpers';

const Dots = ({ currentSlide, goToSlide, slideCount, className }) => {
  const dotSize = 18;
  const margin = 6;

  return (
    <div className="dots-container">
      {[...Array(slideCount)].fill(null).map((_, index) => (
        <div
          key={uniqueId()}
          role="button"
          tabIndex={0}
          className={classnames('dot', className, index === currentSlide && 'active')}
          onClick={() => goToSlide(index)}
          onKeyDown={e => keyPressSpace(e, () => goToSlide(index))}
          aria-label="Change slide"
          style={{
            height: dotSize,
            width: dotSize,
            margin: `1.5rem ${margin}px `,
          }}
        />
      ))}
    </div>
  );
};

export default Dots;
