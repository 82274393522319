import React, { useState, useEffect, useRef } from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';

import t from '../../locales';
import phone from '../../images/phone.png';
import Flex from '../shared/flex';
import Point from '../shared/point';
import Button from '../shared/button';
import Line from '../shared/line';

export const screenImage = graphql`
  fragment screenImage on File {
    childImageSharp {
      fluid(maxWidth: 562, maxHeight: 1224) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
`;

const FeaturesSwitcher = ({ langKey }) => {
  const {
    calendar,
    management,
    payments,
    statistics,
    news,
    news_client,
    rides_management,
    easy_communication,
    horse_management,
    horse_service,
    instructor_availability,
    sms,
    tickets,
    rides_reservation,
    tickets_client,
    payments_client,
  } = useStaticQuery(graphql`
    query {
      calendar: file(relativePath: { eq: "screens/calendar.png" }) {
        ...screenImage
      }
      management: file(relativePath: { eq: "screens/management.png" }) {
        ...screenImage
      }
      payments: file(relativePath: { eq: "screens/payments.png" }) {
        ...screenImage
      }
      statistics: file(relativePath: { eq: "screens/statistics.png" }) {
        ...screenImage
      }
      news: file(relativePath: { eq: "screens/news.png" }) {
        ...screenImage
      }
      news_client: file(relativePath: { eq: "screens/news_client.png" }) {
        ...screenImage
      }
      rides_management: file(relativePath: { eq: "screens/rides_management.png" }) {
        ...screenImage
      }
      easy_communication: file(relativePath: { eq: "screens/easy_communication.png" }) {
        ...screenImage
      }
      horse_management: file(relativePath: { eq: "screens/horse_management.png" }) {
        ...screenImage
      }
      horse_service: file(relativePath: { eq: "screens/horse_service.png" }) {
        ...screenImage
      }
      instructor_availability: file(relativePath: { eq: "screens/instructor_availability.png" }) {
        ...screenImage
      }
      sms: file(relativePath: { eq: "screens/sms.png" }) {
        ...screenImage
      }
      tickets: file(relativePath: { eq: "screens/tickets.png" }) {
        ...screenImage
      }
      rides_reservation: file(relativePath: { eq: "screens/rides_reservation.png" }) {
        ...screenImage
      }
      tickets_client: file(relativePath: { eq: "screens/tickets_client.png" }) {
        ...screenImage
      }
      payments_client: file(relativePath: { eq: "screens/payments_client.png" }) {
        ...screenImage
      }
    }
  `);
  const tabs = ['owner', 'instructor', 'client'];
  const features = {
    owner: [
      {
        key: 0,
        title: t('home.features.owner.0.title'),
        content: t('home.features.owner.0.content'),
        screen: calendar,
      },
      {
        key: 1,
        title: t('home.features.owner.1.title'),
        content: t('home.features.owner.1.content'),
        screen: management,
      },
      {
        key: 2,
        title: t('home.features.owner.2.title'),
        content: t('home.features.owner.2.content'),
        screen: payments,
      },
      {
        key: 3,
        title: t('home.features.owner.3.title'),
        content: t('home.features.owner.3.content'),
        screen: tickets,
      },
      {
        key: 4,
        title: t('home.features.owner.4.title'),
        content: t('home.features.owner.4.content'),
        screen: statistics,
      },
      {
        key: 5,
        title: t('home.features.owner.5.title'),
        content: t('home.features.owner.5.content'),
        screen: news,
      },
    ],
    instructor: [
      {
        key: 10,
        title: t('home.features.instructor.0.title'),
        content: t('home.features.instructor.0.content'),
        screen: calendar,
      },
      {
        key: 11,
        title: t('home.features.instructor.1.title'),
        content: t('home.features.instructor.1.content'),
        screen: rides_management,
      },
      {
        key: 12,
        title: t('home.features.instructor.2.title'),
        content: t('home.features.instructor.2.content'),
        screen: easy_communication,
      },
      {
        key: 13,
        title: t('home.features.instructor.3.title'),
        content: t('home.features.instructor.3.content'),
        screen: horse_management,
      },
      {
        key: 14,
        title: t('home.features.instructor.4.title'),
        content: t('home.features.instructor.4.content'),
        screen: sms,
      },
    ],
    client: [
      {
        key: 20,
        title: t('home.features.client.0.title'),
        content: t('home.features.client.0.content'),
        screen: rides_reservation,
      },
      {
        key: 21,
        title: t('home.features.client.1.title'),
        content: t('home.features.client.1.content'),
        screen: instructor_availability,
      },
      {
        key: 22,
        title: t('home.features.client.2.title'),
        content: t('home.features.client.2.content'),
        screen: news_client,
      },
      {
        key: 23,
        title: t('home.features.client.3.title'),
        content: t('home.features.client.3.content'),
        screen: payments_client,
      },
      {
        key: 24,
        title: t('home.features.client.4.title'),
        content: t('home.features.client.4.content'),
        screen: tickets_client,
      },
      {
        key: 25,
        title: t('home.features.client.5.title'),
        content: t('home.features.client.5.content'),
        screen: horse_service,
      },
    ],
  };

  const [activeTab, setActiveTab] = useState('owner');
  const [index, setAciveIndexes] = useState({ prev: null, current: 0 });
  const [autoPlay, setAutoPlay] = useState(true);
  const intervalId = useRef();

  useEffect(() => {
    intervalId.current = setInterval(changeIndex, 6000);
    return () => {
      clearInterval(intervalId.current);
    };
  });

  function changeIndex(currentIndex = null) {
    const currentTabLength = features[activeTab].length - 1;
    const currentFetaures = features[activeTab];
    const { current } = index;
    let nextIndex = 0;

    if (currentIndex !== null) {
      nextIndex = currentIndex;
    } else {
      nextIndex = current === currentFetaures[currentTabLength].key ? currentFetaures[0].key : current + 1;
    }

    if (autoPlay) {
      setAciveIndexes({ prev: current, current: nextIndex });
    }
  }

  const onMouseEnter = () => {
    setAutoPlay(false);
  };

  const onMouseLeave = () => {
    setAutoPlay(true);
  };

  const refreshInterval = time => {
    clearInterval(intervalId.current);
    intervalId.current = setInterval(changeIndex, time);
  };

  const changeTab = tab => {
    const { prev } = index;
    refreshInterval(6000);
    setActiveTab(tab);
    setAciveIndexes({ prev, current: features[tab][0].key });
  };

  const goToIndex = currentIndex => {
    refreshInterval(6000);
    changeIndex(currentIndex);
  };

  return (
    <>
      <div className="desktop-features">
        <Flex spaceAround className="relative items-center container mb-20">
          <Line />
          {tabs.map(tab => (
            <Button
              key={tab}
              className="teal z-10"
              active={activeTab === tab}
              onClick={() => changeTab(tab)}
              content={t(`general.${tab}`)}
              style={{ minWidth: '225px' }}
            />
          ))}
        </Flex>
        <Flex className="features">
          <div>
            <div role="button" tabIndex={0} className="phone" onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
              <img src={phone} alt="phone" />
              <div className="screens">
                {tabs.map(tab => (
                  <div key={tab}>
                    {features[tab].map(item => (
                      <div
                        key={item.key}
                        className={classnames(
                          'screen',
                          index.current === item.key ? 'active' : 'unactive',
                          index.prev === item.key && 'prev'
                        )}
                      >
                        <Img fluid={item.screen.childImageSharp.fluid} />
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div>
            {features[activeTab].map(item => (
              <Flex
                key={item.key}
                className={classnames('features-content', {
                  active: index.current === item.key,
                })}
              >
                <div>
                  <Point dotSize={35} index={item.key} setActive={() => goToIndex(item.key)} />
                </div>
                <Flex className="feature" column>
                  <div
                    role="button"
                    tabIndex={0}
                    onKeyPress={() => goToIndex(item.key)}
                    onClick={() => goToIndex(item.key)}
                  >
                    {item.title}
                  </div>
                  <div>{item.content}</div>
                </Flex>
              </Flex>
            ))}
          </div>
        </Flex>
      </div>
    </>
  );
};

export default connect(state => ({ langKey: state.app.langKey }))(FeaturesSwitcher);
